/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {useState, useEffect} from "react";

import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import UsersTable from "./components/UsersTable";
import { columnDataUsers } from "./variables/ToiColumns";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'

export default function Users() {

  const [users, setUsers] = useState([]);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState(''); // 'success' or 'error'
  
    const alertStuffs = (type, message) =>{
      setAlertType(type);
      setAlertMessage(message);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 20000);
    };


  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/?get_for_all=true`);
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const loginUser = async (username = null) => {

      const json_data = {
        'username': username
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/`, {
          method: 'POST',
          body: JSON.stringify(json_data),
          headers: {
            'Content-Type': 'application/json',
          },
        });
    
        const data = await response.json();
    
        const successForUsers = [];
        const messageFailed = [];
    
        data.forEach((order) => {
          if (order['status'] === 'SUCCESS') {
            successForUsers.push(order['username']);
          } else {
            messageFailed.push(order['message']);
          }
        });
    
        if (successForUsers.length > 0) {
          alertStuffs('success', `Logged in successfully for ${successForUsers.join(', ')}`);
        } else {
          alertStuffs('error', `Failed to login because ${messageFailed[0]}`);
        }
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    }


  const update_balance = async (username = null) => {
      const json_data = {
        'username': username
      }

      try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/balance/`, {
            method: 'POST',
            body: JSON.stringify(json_data),
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const data = await response.json();
      
          const successForUsers = [];
          const messageFailed = [];
      
          data.forEach((order) => {
            if (order['status'] === 'success') {
              successForUsers.push(order['username']);
            } else {
              messageFailed.push(order['message']);
            }
          });
      
          if (successForUsers.length > 0) {
            alertStuffs('success', `Updated balance successfully for ${successForUsers.join(', ')}`);
          } else {
            alertStuffs('error', `Failed to update balance because ${messageFailed[0]}`);
          }
        } catch (error) {
          console.error('Error fetching logs:', error);
        }
      };


  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 1000 * 2000);
    return () => clearInterval(intervalId);
  }, []);


  const AlertMessage = () => {
    return (
      <Alert
        status={alertType}
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          {alertType === 'success' ? 'Successfull!' : 'Failed!'}
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          {alertMessage}
        </AlertDescription>
      </Alert>
    );
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {showAlert && <AlertMessage />}
      <SimpleGrid
        mb='20px'
        columns={{ base: 1, md: 1, xl: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <UsersTable
          columnsData={columnDataUsers}
          tableData={users}
          Login={loginUser}
          updateBalance={update_balance}
        />
      </SimpleGrid>
    </Box>
  );
}
