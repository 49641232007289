export const columnDataPosition = [
  {
    Header: "Username",
    accessor: "user_id",
  },
  {
    Header: "Symbol",
    accessor: "symbol",
  },
  {
    Header: "Direction",
    accessor: "direction",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Entry Time",
    accessor: "entry_time",
  },
  {
    Header: "Entry Price",
    accessor: "entry_price",
  },
  {
    Header: "Entry Quantity",
    accessor: "entry_quantity",
  },
  {
    Header: "Current Price",
    accessor: "current_price",
  },
  {
    Header: "Current Quantity",
    accessor: "current_quantity",
  },
  {
    Header: "Realized pnl",
    accessor: "realized_pnl",
  },
  {
    Header: "UnRealized pnl",
    accessor: "unrealized_pnl",
  },
  {
    Header: "Stop Loss",
    accessor: "stop_loss",
  },
  {
    Header: "Take Profit 1",
    accessor: "tp1",
  },
  {
    Header: "Take Profit 2",
    accessor: "tp2",
  },
  {
    Header: "Take Profit 3",
    accessor: "tp3",
  },
  {
    Header: "Trailing stoploss",
    accessor: "trailing_stop_loss",
  },
  {
    Header: "Market",
    accessor: "market",
  },
  {
    Header: "Source",
    accessor: "source"
  }
];

