export const columnDataOrders = [
  {
    Header: "Order ID",
    accessor: "order_id",
  },
  {
    Header: "Symbol",
    accessor: "symbol",
  },
  {
    Header: "Username",
    accessor: "user_id",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Order Reason",
    accessor: "order_reason",
  },
  {
    Header: "Side",
    accessor: "side",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Timestamp",
    accessor: "timestamp",
  },
  {
    Header: "Order Type",
    accessor: "order_type",
  },
  {
    Header: "Market",
    accessor: "market",
  }
];

