import React from 'react';
import { Navigate } from 'react-router-dom';
import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdHome,
  MdLocalGasStation,
  MdOutlineShoppingCart,
  MdVerifiedUser,
  MdLock,
  Md10Mp,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import SignalPosition from 'views/admin/signal-position';
import SignalOrder from 'views/admin/signal-order';
import Orders from 'views/admin/orders';
import DataTables from 'views/admin/dataTables';
import LogsTable from 'views/admin/logs';
import Users from 'views/admin/users';
// Auth Imports
// Auth Imports
import SignInCentered from 'views/auth/signIn';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('token');
  console.log(isAuthenticated);
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/auth/sign-in" replace />;
};

const SignOut = () => {
  localStorage.removeItem('token');
  return (<Navigate to="/auth/sign-in" replace />);
}

const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <ProtectedRoute component={MainDashboard} />,
  },
  {
    name: 'Orders',
    layout: '/admin',
    path: '/nft-marketplace',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <ProtectedRoute component={Orders} />,
    secondary: true,
  },
  {
    name: 'Positions',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables',
    component: <ProtectedRoute component={DataTables} />,
  },
  {
    name: 'Logs',
    layout: '/admin',
    icon: <Icon as={MdLocalGasStation} width="20px" height="20px" color="inherit" />,
    path: '/logs',
    component: <ProtectedRoute component={LogsTable} />,
  },
  {
    name: 'Users',
    layout: '/admin',
    icon: <Icon as={MdVerifiedUser} width="20px" height="20px" color="inherit" />,
    path: '/users',
    component: <ProtectedRoute component={Users} />,
  },

  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  },
  {
    name: 'Signal Positions',
    layout: '/admin',
    path: '/signal-positions',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <ProtectedRoute component={SignalPosition} />,
  },
  {
    name: 'Signal Orders',
    layout: '/admin',
    path: '/signal-orders',
    icon: <Icon as={Md10Mp} width="20px" height="20px" color="inherit" />,
    component: <ProtectedRoute component={SignalOrder} />,
  },
  {
    name: 'Sign Out',
    layout: '/auth',
    path: '/sign-out',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignOut />
  },

];

export default routes;