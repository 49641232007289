import React from "react";

// Chakra imports
import { Flex } from "@chakra-ui/react";
import { Text } from '@chakra-ui/react';



export function SidebarBrand() {
  //   Chakra color mode

  return (
    <Flex align='center' direction='column'>
    <Text fontSize='4xl'>Algonet</Text>
    </Flex>
  );
}

export default SidebarBrand;
