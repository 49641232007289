/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {useState, useEffect} from "react";

import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import OrdersTable from "./components/OrdersTable";
import { columnDataOrders } from "./variables/ToiColumns";

export default function SignalOrder() {

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/signals/order/`);
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };
    fetchOrders();
    // Fetch logs every second
    const intervalId = setInterval(fetchOrders, 1000 * 3);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ base: 1, md: 1, xl: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <OrdersTable
          columnsData={columnDataOrders}
          tableData={orders}
        />
      </SimpleGrid>
    </Box>
  );
}
