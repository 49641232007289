export const columnDataUsers = [
  {
    Header: "Status",
    accessor: "status", // Maps to the balance field
  },
  {
    Header: "Username",
    accessor: "username", // Maps to the username field
  },
  {
    Header: "Contact Number",
    accessor: "contact_number", // Maps to the contact_number field
  },
  {
    Header: "Daily PnL",
    accessor: "daily_pnl", // Maps to the daily_pnl field
  },
  {
    Header: "Weekly PnL",
    accessor: "weekly_pnl", // Maps to the weekly_pnl field
  },
  {
    Header: "Monthly PnL",
    accessor: "monthly_pnl", // Maps to the monthly_pnl field
  },
  {
      Header: "Quaterly PnL",
      accessor: "quarterly_pnl", // Maps to the quaterly_pnl field
  },
  {
      Header: "Yearly PnL",
      accessor: "yearly_pnl" // Maps to the yearly_pnl field
  },
  {
      Header: "Total PnL",
      accessor: "total_pnl", // Maps to the total_pnl field
  },
  {
    Header: "Commission",
    accessor: "commission", // Maps to the commission field
  },
  {
    Header: "Live Status",
    accessor: "live", // Maps to the live field
    Cell: ({ value }) => (value ? "Yes" : "No"), // Optional: Display "Yes" or "No" for boolean values
  },
  {
    Header: "Group",
    accessor: "group", // Maps to the group field
  },
  {
    Header: "Balance",
    accessor: "balance", // Maps to the balance field
  },
  {
    Header: "Blacklist",
    accessor: "blacklist", // Maps to the balance field
  },
  {
    Header: "Sharpe Ratio",
    accessor: "sharpe_ratio", // Maps to the sharpe_ratio field
  },
  {
    Header: "Sortino Ratio",
    accessor: "sortino_ratio", // Maps to the sortino_ratio field
  },
  {
    Header: "Max Drawdown",
    accessor: "max_drawdown", // Maps to the max_drawdown field
  },
  {
    Header: "Volatility",
    accessor: "volatility", // Maps to the volatility field
  },
];
