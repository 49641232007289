/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Box, SimpleGrid } from "@chakra-ui/react";
import PositionTable from "views/admin/dataTables/components/PositionsTable";
import {
  columnDataPosition
} from "views/admin/dataTables/variables/ToiColumns";
import React , {useEffect, useState} from "react";
import { Button } from "@chakra-ui/react"
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'


const processPosition = (item) => {
  // Create a new object to avoid mutating the original item
  const newItem = { ...item };
  // Iterate through the object keys
  Object.keys(newItem).forEach(key => {
    if (typeof newItem[key] === 'number') {
      newItem[key] = Math.round(newItem[key] * 1000) / 1000; // Round to 3 decimal places
    }
  });
  return newItem;
};

export default function Settings() {
  const [positions, setPositions] = useState([]);
  const [closeAllPositionsIsLoading, setcloseAllPositionsIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'
  const [alertMessage, setAlertMessage] = useState('');

  const alertStuffs = (type, message) =>{
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 20000);
  };

  const fetchPositions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/position/`);
      const data = await response.json();
      const processedData = data.map(processPosition);
      setPositions(processedData);
    } catch (error) {
      console.error('Error fetching positions:', error);
    }
  };
  useEffect(() => {
    fetchPositions();
    // Fetch logs every second
    const intervalId = setInterval(fetchPositions, 1000 * 10);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleCloseAllPositions = async () => {
    console.log("Closing all positions");
    setcloseAllPositionsIsLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/position/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then(data => {
          fetchPositions();
          setcloseAllPositionsIsLoading(false);
          const successForUsers = [];
          const messageFailed = [];     
          data.forEach((order) => {
            if (order['status'] === 'SUCCESS'){
              successForUsers.push(order['username']);
            } else {
              messageFailed.push(order['message']);
            }
          });
          if (successForUsers.length > 0){
            alertStuffs('success', `Order closed successfully for ${successForUsers.join(', ')}`);
          } else {
            alertStuffs('error', `Failed to close position because ${messageFailed[0]}`);
          }
        })
      } catch (error) {
        console.error('Error closing all positions:', error);
    }
  }

  const ClosePositionAlert = () => {
    return (
      <Alert
        status={alertType}
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          {alertType === 'success' ? 'Order Submitted!' : 'Order Failed!'}
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          {alertMessage}
        </AlertDescription>
      </Alert>
    );
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box display="flex" justifyContent="center">
        <Button
          isLoading={closeAllPositionsIsLoading}
          loadingText='Loading'
          colorScheme='yellow'
          variant='outline'
          spinnerPlacement='start'
          width="20%"
          alignSelf="center"
          m="5"
          onClick={handleCloseAllPositions}
        >
          Close All Positions
        </Button>
      </Box>
      {showAlert && <ClosePositionAlert />}
      <SimpleGrid
        mb='20px'
        columns={{ base: 1, md: 1, xl: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <PositionTable
          columnsData={columnDataPosition}
          tableData={positions}
          fetchPositions={fetchPositions}
        />
      </SimpleGrid>
    </Box>
  );
}
